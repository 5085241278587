@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap");

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: darken(white, 40%);
}
::-webkit-scrollbar-thumb {
  background-color: darken(white, 70%);
  border-radius: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 7px;
}
::-webkit-scrollbar-track:horizontal {
  background-color: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: darken(white, 70%);
  border-radius: 5px;
}

* {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: Roboto;
  box-sizing: border-box;
  line-height: 1.4rem;
}
html {
  scroll-behavior: smooth;
}
h1 {
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 1px;
}
h2,
h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
p {
  font-size: 1rem;
}
a {
  text-decoration: none;
}
