@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

$light-grey: rgb(240, 240, 240);
$grey: rgb(210, 210, 210);
$green: rgb(0, 204, 34);
$red: rgb(255, 0, 0);

$main-section: rgb(215, 215, 215);

.app {
  width: 100%;
  text-align: center;
}
.notice {
  display: none;
}
.app {
  @include flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  .app-header,
  .sheet-head,
  .add-to-sheet,
  .data-sheet,
  .action-box {
    width: 100%;
  }
  .app-header,
  .results,
  .action-box {
    z-index: 100;
  }
  .add-to-sheet {
    z-index: 50;
  }
  .data-sheet {
    z-index: 1;
  }
  .neut {
    color: black;
  }
  .blue {
    color: steelblue;
  }
  .pos {
    color: $green;
  }
  .neg {
    color: $red;
  }
  .greetbox {
    position: relative;
    @include flex;
    flex-direction: column;
    height: 100vh;
    .greet-logo {
      z-index: -1;
      position: fixed;
      min-height: 100%;
      opacity: 0.6;
    }
    .inner-box {
      width: 100vw;
      padding: 25px 0;
      border-top: 2px solid steelblue;
      border-bottom: 2px solid steelblue;
      background-color: rgba(255, 255, 255, 0.7);
      h1 {
        padding-bottom: 25px;
        font-size: 2rem;
      }
      input {
        border: none;
        outline: none;
        padding: 2px 5px;
        border-bottom: 2px solid steelblue;
        background-color: transparent;
      }
      button {
        border: none;
        outline: none;
        font-size: 0.9rem;
        padding: 2px 15px;
        margin-top: 20px;
        border-radius: 5px;
        border: 2px solid steelblue;
        background-color: $light-grey;
        transition: 0.05s;
        &:active {
          transition: 0.05s;
          transform: scale(0.98);
        }
      }
    }
  }
  .app-header {
    overflow: hidden;
    position: relative;
    min-height: 175px;
    .banner {
      @include flex;
      align-items: flex-end;
      height: 175px;
      img {
        width: 100%;
        min-height: 245px;
      }
    }
    .cc {
      position: absolute;
      left: 15px;
      bottom: 10px;
      width: 50%;
    }
    .username {
      position: absolute;
      font-size: 0.8rem;
      left: 35px;
      bottom: 25px;
      color: white;
      text-transform: capitalize;
    }
    .logout {
      box-sizing: content-box;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 20px;
      padding: 3px;
      border-radius: 50%;
      background-color: $light-grey;
    }
    .menu-btn {
      box-sizing: content-box;
      @include flex;
      z-index: 100;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      font-size: 1.4rem;
      color: black;
      padding: 3px;
      border-radius: 50%;
      background-color: $light-grey;
      transform: rotate(180deg);
    }
    .inv {
      color: black;
      background-color: unset;
    }
    .header-menu {
      z-index: 50;
      position: relative;
      min-height: 200px;
      height: 100%;
      background-color: $light-grey;
      .compose-btns-cont {
        z-index: 100;
        position: absolute;
        @include flex;
        width: 100%;
        height: 40px;
        bottom: 0px;
        border-top: 2px solid steelblue;
        .divider {
          width: 1px;
          height: 100%;
          background-color: steelblue;
        }
        .menu-active-text {
          flex: 1.5;
          text-transform: capitalize;
          font-weight: bold;
        }
        .menu-btn {
          color: black;
          all: unset;
          flex: 1;
          font-weight: bold;
          @include flex;
          transition: all 0.05s;
          &:active {
            transition: all 0.05s;
            transform: scale(0.98);
          }
        }
      }
      .composing-input {
        outline: none;
        border: none;
        padding: 1px 0;
        padding-left: 10px;
        margin-top: 5px;
        border-radius: 0px;
        border-left: 2px solid steelblue;
        border-bottom: 2px solid steelblue;
        background-color: transparent;
        font-size: 16px;
      }
      .budget-list {
        text-align: left;
        width: 100%;
        padding: 15px 15px;
        padding-bottom: 50px;
        overflow: hidden;
        h1 {
          width: 100%;
          font-weight: bold;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 2px solid steelblue;
        }
        .item {
          @include flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 5px;
          li {
            cursor: pointer;
            text-transform: capitalize;
            width: fit-content;
            padding: 1px 10px 1px 0;
            text-indent: 10px;
            list-style: none;
            border-left: 2px solid steelblue;
            border-bottom: 2px solid steelblue;
            transition: all 0.05s;
            &:active {
              transition: all 0.05s;
              transform: scale(0.98);
            }
          }
          img {
            width: 20px;
          }
        }
      }
    }
  }
  .sheet-head {
    z-index: 10;
    @include flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
    height: 40px;
    padding: 0px 15px;
    border-top: 2px solid steelblue;
    border-bottom: 2px solid steelblue;
    box-shadow: 0px 0px 10px -2px black;
    background-color: $light-grey;
    p {
      font-weight: bold;
    }
    .delete {
      @include flex;
      justify-content: flex-end;
      width: 18px;
      transition: all 0.05s;
      &:active {
        transition: all 0.05s;
        transform: scale(0.98);
      }
    }
  }
  .add-to-sheet {
    @include flex;
    height: 40px;
    border-top: 1px solid steelblue;
    border-bottom: 1px solid steelblue;
    background-color: $light-grey;
    .inputs {
      flex: 1;
      padding: 0 20px;
      @include flex;
      justify-content: space-between;
      input {
        width: 45%;
        font-size: 16px;
        outline: none;
        border: none;
        text-align: left;
        border-radius: 0px;
        background-color: transparent;
      }
      input::placeholder {
        font-size: 0.85rem;
      }
      input:nth-child(2) {
        text-align: right;
      }
    }
  }
  .data-sheet {
    margin-bottom: 43px;
    #incomes,
    #expenses {
      .section-head {
        @include flex;
        justify-content: space-between;
        padding: 5px 15px;
        margin-top: 10px;
        .section-title {
          text-align: left;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
      .item:not(:nth-child(2)) {
        margin-top: 3px;
      }
      .item {
        @include flex;
        justify-content: space-between;
        height: 40px;
        padding: 0 15px;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        background-color: $light-grey;
        .item-name {
          text-transform: capitalize;
        }
        .delete-checkbox {
          @include flex;
          justify-content: flex-end;
          height: 100%;
          width: 30px;
        }
      }
    }
  }
  .action-box {
    position: fixed;
    bottom: 0;
    @include flex;
    justify-content: space-evenly;
    height: 40px;
    border-top: 2px solid steelblue;
    background-color: $light-grey;
    box-shadow: 0px 0px 10px -2px black;
    .divider {
      width: 1px;
      height: 100%;
      background-color: steelblue;
    }
    button {
      all: unset;
      @include flex;
      width: 50%;
      height: 100%;
      font-weight: bold;
      &:active {
        transform: scale(0.97);
      }
    }
  }
}

@media (min-width: 500px) {
  $size: 500px;
  .notice {
    display: block;
    user-select: none;
    color: black;
    text-align: center;
    margin: 20px auto;
    padding-bottom: 5px;
    width: $size;
    border-bottom: 2px solid steelblue;
  }
  .app {
    overflow: hidden;
    max-width: $size;
    min-height: 60vh;
    max-height: 60vh;
    margin: 0 auto;
    border-radius: 10px;
    border: 3px solid black;
    background-color: white;
  }
  .greetbox {
    z-index: 1;
    .greet-logo {
      position: absolute !important;
      opacity: 0.6 !important;
    }
  }
  .app-header {
    user-select: none;
    min-height: 215px !important;
    .banner {
      height: 215px !important;
    }
    .logout,
    .menu-btn {
      cursor: pointer !important;
    }
    .header-menu {
      min-height: 220px !important;
      .menu-btn {
        cursor: pointer !important;
      }
      .budget-list {
        .item {
          img {
            height: 100%;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .sheet-head {
    min-height: 40px !important;
    .delete {
      user-select: none;
      cursor: pointer;
    }
  }
  .data-sheet {
    flex: 1;
    overflow: scroll;
    margin-bottom: 0px !important;
    background-color: $light-grey;
    .section-head {
      margin-top: 0px !important;
      padding-top: 10px !important;
    }
    .item {
      .delete-checkbox {
        cursor: pointer !important;
        input {
          cursor: pointer !important;
        }
      }
    }
  }
  .action-box {
    position: unset !important;
    max-width: $size;
    button {
      user-select: none;
      cursor: pointer !important;
    }
  }
}
